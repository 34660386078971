<template>
  <div>
    <div class="text-right mb-2 col-10 offset-1">
      <CButton color="primary" to="/tfw/partner/profile">Back</CButton>
    </div>
    <div>
      <component v-if="partnerInfo" :is="partnerInfo.component" />
    </div>
    <!-- <div v-if="partnerPage">
      <iframe
        id="iframe"
        ref="iframe"
        :src="partnerPageURL"
        onload='javascript:(function(o){o.style.height=o.contentWindow.document.body.scrollHeight+"px";}(this));'
        style="height: 100vh; width: 100%; border: none; overflow: hidden"
      ></iframe>
    </div>
    <div v-else>
      <component v-if="partnerInfo" :is="partnerInfo.component" />
    </div> -->
  </div>
</template>
<script>
import SLCProfile from "@/components/reusable/ProfilePage/SLCPage";
import DOHProfile from "@/components/reusable/ProfilePage/DOHPage";
import NHSProfile from "@/components/reusable/ProfilePage/NHSPage";

import { CONSTANT } from "@/helpers/helper";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { BASE_URL } from "@/service_urls";

Vue.component("SLCProfile", SLCProfile);
Vue.component("DOHProfile", DOHProfile);
Vue.component("NHSProfile", NHSProfile);
export default {
  data() {
    return {
      partnerInfo: null,
    };
  },
  computed: {
    ...mapGetters(["partnersProfilePages"]),
    partnerPage() {
      return this.partnersProfilePages?.length
        ? this.partnersProfilePages?.[0]
        : null;
    },
    partnerPageURL() {
      return this.partnersProfilePages?.length
        ? `${BASE_URL}${this.partnersProfilePages[0].link_to_doc?.replace(
            "/api/v1",
            ""
          )}`
        : false;
    },
  },
  methods: {
    ...mapActions(["getPartnersPages"]),
    updateProfilePage() {
      const { id } = this.$route.params;
      const partners = CONSTANT.partners;
      this.partnerInfo = partners.find((v) => v.partner_id == id);
    },
    initAction() {
      this.isLoading = true;
      this.getPartnersPages({
        all_rows: true,
        fetch_row_count: false,
        document_type_id: 62, // Profile page Document type id,
        organisation_id: 166,
        order_by: "-organisation_document_id",
      }).then((res) => {
        this.isLoading = false;
      });
    },
  },
  created() {
    this.updateProfilePage();
    // this.initAction();
  },
  watch: {
    "$route.params": function () {
      this.updateProfilePage();
    },
  },
};
</script>
